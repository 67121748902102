import React from "react";

const Contact = () => {
  return (
    <div
      name="Contact"
      className="w-full h-fit bg-gradient-to-b from-black to-gray-700 pt-10 flex flex-col justify-center items-center"
    >
      <div className="max-w-screen-lg mx-auto p-4 w-full h-full">
        <div>
          <p className="text-white text-4xl font-bold inline border-b-4 border-gray-500 ">
            Contact
          </p>
          <p className="py-6 text-white">
            Submit this form to get in touch with me
          </p>
        </div>
        <div>
          <form
            action="https://getform.io/f/a787d22f-7eb0-4461-b0cd-715462419ce9"
            method="POST"
            className="flex flex-col justify-center items-center gap-y-7 py-10"
          >
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className=" p-4 bg-transparent border-2 rounded-md text-white focus:outline-none w-[70%] md:w-[50%]"
            />
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              className="p-4 bg-transparent border-2 rounded-md text-white focus:outline-none w-[70%] md:w-[50%]"
            />
            <textarea
              name="message"
              placeholder="Enter your message"
              className="p-4 bg-transparent border-2 rounded-md text-white focus:outline-none w-[70%] md:w-[50%] h-[200px]"
            />
            <input
              type="submit"
              value="submit"
              className="px-4 py-2 text-white bg-gradient-to-b from-sky-400 to-blue-700 w-[120px] rounded-lg cursor-pointer "
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
