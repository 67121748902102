import React from "react";

import videocloneapp from "../assets/portfolio/videocloneapp.jpg";

const Portfolio = () => {
  const portfolios = [
    {
      id: 1,
      img: videocloneapp,
      url: "https://glittering-salmiakki-81d212.netlify.app",
      github: "https://github.com/HBAQQAL/videocloneapi",
    },
    {
      id: 2,
      img: "https://play-static.unity.com/20240224/p/images/3353b0ae-d399-48e3-a324-4fccb0687bb1_CRAZYBIRD.png",
      url: "https://play.unity.com/mg/other/webgl-builds-372969",
      github: "https://github.com/HBAQQAL/Crazy-Bird-Video-Game",
    },
    {
      id: 3,
      img: "https://is1-ssl.mzstatic.com/image/thumb/Purple125/v4/b7/f2/f7/b7f2f743-a446-f909-5469-b260a4f80df4/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x600wa.png",
      url: "https://github.com/HBAQQAL/-JavaChatApp",
      github: "https://github.com/HBAQQAL/-JavaChatApp",
    },
    {
      id: 4,
      img: "https://aichat.com/wp-content/uploads/2019/06/aichat-banner-logo.jpg",
      url: "https://ask-baqqal.vercel.app/",
      github: "https://github.com/HBAQQAL/AskBaqqal",
    },
  ];
  return (
    <div
      name="Portfolio"
      className="bg-gradient-to-b from-black pt-10 to-gray-900 w-full text-white   md:h-screen">
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-4">
          <p className="text-white text-4xl font-bold inline border-b-4 border-gray-500">
            {" "}
            Portfolio
          </p>
          <p className="py-6">Check out some of my work right here</p>
          <div className="flex flex-wrap justify-evenly items-center w-[95%] mx-auto px-4  gap-y-10">
            {portfolios.map(({ id, img, url, github }) => {
              return (
                <div className="flex flex-col justify-center items-center shadow-md shadow-gray-400 rounded-lg ">
                  <img
                    src={img}
                    alt=""
                    width={250}
                    height={350}
                    className="rounded-md duration-200 hover:scale-105"
                  />
                  <div className="w-full flex justify-center items-center ">
                    <button
                      className="w-1/2 px-6 py-3 m-4 text-center hover:scale-105 "
                      onClick={() => {
                        window.open(url);
                      }}>
                      Demo
                    </button>
                    <button
                      className=" text-center w-1/2 px-6 py-3 m-4  hover:scale-105"
                      onClick={() => {
                        window.open(github);
                      }}>
                      Code
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
