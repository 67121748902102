import React from "react";
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import nextjs from "../assets/nextjs.png";
import github from "../assets/github.png";
import node from "../assets/node.png";
import react from "../assets/react.png";
import tailwind from "../assets/tailwind.png";
import graphql from "../assets/graphql.png";
import java from "../assets/java.png";
import unity from "../assets/unity.png";
import laravel from "../assets/laravel.png";

const Experience = () => {
  const tools = [
    {
      id: 1,
      img: html,
      title: "html",
      style: "shadow-orange-600",
    },
    {
      id: 2,
      img: css,
      title: "css",
      style: "shadow-blue-600",
    },
    {
      id: 3,
      img: javascript,
      title: "javascript",
      style: "shadow-yellow-600",
    },
    {
      id: 4,
      img: react,
      title: "react",
      style: "shadow-blue-600",
    },
    {
      id: 5,
      img: node,
      title: "node",
      style: "shadow-green-600",
    },
    {
      id: 6,
      img: github,
      title: "github",
      style: "shadow-gray-600",
    },
    {
      id: 7,
      img: tailwind,
      title: "tailwind",
      style: "shadow-gray-600",
    },
    {
      id: 8,
      img: nextjs,
      title: "nextjs",
      style: "shadow-gray-200",
    },
    {
      id: 9,
      img: graphql,
      title: "graphql",
      style: "shadow-red-600",
    },
    {
      id: 10,
      img: java,
      title: "java",
      style: "shadow-orange-700",
    },
    {
      id: 11,
      img: unity,
      title: "unity ",
      style: "shadow-blue-300",
    },
    {
      id: 12,
      img: laravel,
      title: "laravel",
      style: "shadow-red-600",
    },
  ];
  return (
    <div
      name="Experience"
      className="bg-gradient-to-b from-gray-900 to-black w-full h-fit box-border pt-10"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center  h-fit w-full">
        <div>
          <p className="text-white text-4xl font-bold inline border-b-4 border-gray-500">
            {" "}
            Experience
          </p>
          <p className="py-6 text-white">
            {" "}
            These are the technologies I've worked with
          </p>
        </div>
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-5 px-12 sm:px-0">
          {tools.map(({ id, img, title, style }) => {
            return (
              <div
                key={id}
                className={
                  "shadow-md hover:scale-105 duration-300 py-4 rounded-lg " +
                  style
                }
              >
                <img src={img} alt="" className="w-20 mx-auto" />
                <p className="mt-4 text-white">{title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Experience;
