import About from "./Components/About";
import Contact from "./Components/Contact";
import Experience from "./Components/Experience";
import Home from "./Components/Home";
import NavBar from "./Components/NavBar";
import Portfolio from "./Components/Portfolio";
import SocialLinks from "./Components/SocialLinks";
import Particles from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import part from "./assets/part1.png"

// portfolio button
function App() {

  const init = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <div className="flex items-center flex-col">
      <NavBar />
      <Home />
      <About />
      <Portfolio />
      <Experience />
      <Contact />
      <SocialLinks />
      <Particles
  options={{
    particles: {
      shape: {
       // Set the shape to image
        type: "image",
        // Set the image to the pizza emoji
        image: {
          src: part,
          width: 300,
          height: 300,
          
        }
      },
      number: {
        value: 10, // Increase the number of particles
        density: {
          enable: true,
          value_area: 1000, // Decrease the value to make particles more spread out
        },
      },
      line_linked: {
        enable: true,
        opacity: 0.2,
        color: "#ffffff",
 
      },
      move: {
        enable: true,
        // direction to bottom
        direction: "random",
        // speed of the particles
        speed: 0.5,


      },
      size: {
        value: 5, // Increase the particle size
      },
      opacity: {
        anim: {
          enable: true,
          speed: 0.5,
          opacity_min: 0.1,
          opacity_max: 0.4,
        },
        

      },
    },
    interactivity: {
      events: {
        onclick: {
          enable: true,
          // Change the mouse interaction to flashlight for a fun effect
          mode : "push",
        },
      },
      modes: {
        push: {
          particles_nb: 1, // Increase the number of particles pushed on click
        },
  
      },
    },
    retina_detect: true,
  }}
  init={init}
  className="z-40"
/>

      </div>
  );
}

export default App;
