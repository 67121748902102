import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import cv from "../cv.pdf";

const SocialLinks = () => {
  const links = [
    {
      id: 1,
      child: (
        <>
          {" "}
          Linkedin <FaLinkedin size={30} className="text-blue-800" />
        </>
      ),
      href: "https://www.linkedin.com/in/hamza-baqqal-aa8132245",

      style: " text-yellow-800",
    },
    {
      id: 2,
      child: (
        <>
          {" "}
          Github <FaGithub size={30} className="text-green-900" />
        </>
      ),
      href: "https://github.com/HBAQQAL",
      style: " text-black",
    },
    {
      id: 3,
      child: (
        <>
          {" "}
          Email <HiOutlineMail size={30} className="text-red-900" />
        </>
      ),
      href: "hamzabaqqal2002@gmail.com",
      style: " text-black",
    },
    {
      id: 4,
      child: (
        <>
          {" "}
          Resume <BsFillPersonLinesFill size={30} className="text-yellow-800" />
        </>
      ),
      href: cv,
      style: "rounded-br-md text-black",
    },
  ];
  return (
    <div className=" hidden  lg:flex flex-col top-[35%] left-0  fixed">
      <ul>
        {links.map(({ id, child, href, style }) => {
          return (
            <li
              key={id}
              className={
                "flex justify-between items-center w-40 h-12 px-4 ml-[-100px] duration-200 hover:ml-[-6px] bg-black" +
                style
              }>
              <a
                href={href}
                className={
                  "flex justify-between items-center w-full text-yellow-50"
                }>
                {child}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SocialLinks;
