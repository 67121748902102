import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
import logo from "../assets/logo.png";
// done
const NavBar = () => {
  const [nav, setNav] = useState(false);
  const links = [
    {
      id: 1,
      text: "Home",
    },
    {
      id: 2,
      text: "About",
    },
    {
      id: 3,
      text: "Portfolio",
    },
    {
      id: 4,
      text: "Experience",
    },
    {
      id: 5,
      text: "Contact",
    },
  ];
  return (
    <div className="flex justify-between items-center w-full h-24  max-w-screen-lg mx-auto   text-white fixed  bg-black z-50 mb-10 ">
      <div>
        <img src={logo} alt="logo" className="w-40 h-[100%] " />
      </div>
      <ul className="hidden md:flex">
        {links.map(({ id, text }) => {
          return (
            <li
              key={id}
              className="px-4 cursor-pointer capitalize font-medium text-gray-300 hover:scale-105 duration-200 "
            >
              <Link to={text} smooth duration={500}>
                {text}
              </Link>
            </li>
          );
        })}
      </ul>
      <div
        onClick={() => {
          setNav(!nav);
        }}
        className="cursor-pointer text-gray-500 pr-4 z-10  duration-300 md:hidden"
      >
        {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {nav && (
        <ul className="flex flex-col  justify-center bg-gradient-to-b from-black to-gray-800  w-full items-center absolute  top-0 left-0 h-screen text-gray-500">
          {links.map(({ id, text }) => {
            return (
              <li key={id} className="px-4 cursor-pointer capitalize py-6">
                <Link
                  to={text}
                  smooth
                  duration={500}
                  onClick={() => {
                    setNav(false);
                  }}
                >
                  {text}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default NavBar;
