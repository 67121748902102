import React from "react";

import ProfileImagePng from "../assets/bg.png";

// import download icon from react-icons . md and use it in the button below to download the cv file  .
import { FaDownload } from "react-icons/fa";
import { TbWriting } from "react-icons/tb";

import cv from "../cv.pdf";
import essay from "../s4essay.pdf";

const Home = () => {
  return (
    <div
      name="Home"
      className="h-screen w-full  bg-gradient-to-b from-black via-black to-gray-900 relative pt-11">
      <div className="max-w-screen-lg mx-auto flex flex-col  justify-between  items-center h-full md:flex-row pt-20">
        <div className="flex flex-col justify-center h-full ">
          <h2 className="sm:text-7xl font-light text-white max-w-[100%]">
            Full stack developer
          </h2>
          <p className="text-gray-100 sm:text-2xl py-10 max-w-7xl font-semibold">
            Software engineering student (4 years!), eager to learn & build.
            I've created user-friendly web/mobile apps using MERN STACK.
            Continuously expanding my skillset in software development.
          </p>
          <div className="flex flex-row items-center justify-start">
            <button
              className="group text-white px-6 w-fit py-2 mx-4 flex justify-center items-center bg-gradient-to-r from-blue-600 via-blue-500 to-blue-500 rounded-lg"
              onClick={() => {
                window.open(cv);
              }}>
              <a href={cv}>RESUME</a>

              <span className="group-hover:text-blue-400 duration-300">
                <FaDownload size={15} className="ml-5" />
              </span>
            </button>
            <button
              className="group text-white px-6 w-fit py-2 mx-4 flex justify-center items-center bg-gradient-to-r from-blue-600 via-blue-500 to-blue-500 rounded-lg"
              onClick={() => {
                window.open(essay);
              }}>
              <a href={essay}>ESSAY</a>

              <span className="group-hover:text-blue-400 duration-300">
                <TbWriting size={24} className="ml-5" />
              </span>
            </button>
          </div>
        </div>
        <div className="w-full   -mr-10  rounded-full h-full overflow-visible  flex items-end justify-end">
          <img
            src={ProfileImagePng}
            alt="me"
            className="hidden md:flex   lg:w-[120%] h-[125%]  z-40"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
