import React from "react";
import about from "../assets/about.png";

const About = () => {
  return (
    <div
      name="About"
      className="w-full h-screen bg-gradient-to-b from-gray-900 to-black text-white z-200">
      <div className="max-w-screen-lg p-4 mx-auto h-full flex flex-col justify-center ">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>
        <div className="flex flex-row     lg:justify-between lg:items-center">
          <div className=" lg:w-[50%]">
            <p className="text-xl mt-15">
              Code became my world back in 2019, when I started my software
              engineering journey. Since then, I've been building web apps like
              crazy to sharpen my skills. I'm always on the hunt for exciting
              new tech, especially anything web development related. But coding
              isn't the only thing that gets me fired up = I also love creating
              video games!
            </p>
          </div>
          <div className="hidden lg:flex justify-items-end items-end w-[50%]">
            <img src={about} className="scale-90" alt="hamza" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
